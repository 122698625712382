<template>
  <div class="modal fade " :id="modalName" tabindex="-1" role="dialog" :aria-labelledby="modalName" aria-hidden="true">
    <div class="modal-dialog  modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">{{ modalTitle }}</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row ">
              <div v-if="type === null" class="col-12 col-md-4">
                <div class="form-group">
                  <label>Type *</label>
                  <VueMultiselect v-model="selectedType" :options="types" :close-on-select="true" placeholder="Select state" label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
                  <div class="text-danger" v-if="errors.type">{{ errors.type }}</div>
                </div>
              </div>
              <div :class="typeWiseDivClass">
                <div class="form-group">
                  <label>{{ valueLabel }} *</label>
                  <input v-model="postUserAdditionalInfoData.value" type="text" class="form-control " :placeholder="`Enter ${valueLabel}`" />
                  <div class="text-danger" v-if="errors.value">{{ errors.value }}</div>
                  <div class="text-danger" v-if="errors.user_id">{{ errors.user_id }}</div>
                </div>
              </div>
              <div :class="typeWiseDivClass">
                <div class="form-group">
                  <label>Label *</label>
                  <input v-model="postUserAdditionalInfoData.label" type="text" class="form-control " placeholder="Example: Home, Work, Office, Personal" />
                  <div class="text-danger" v-if="errors.label">{{ errors.label }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="submit" class="btn btn-primary" @click="userAdditionalInfoId !== null ? updateSingleUserAdditionalInfo() : createSingleUserAdditionalInfo()">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">{{ userAdditionalInfoId !== null ? "Update Changes" : "Save Changes" }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
//mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

// core packages
import { mapGetters,mapActions } from 'vuex';

// packages
import VueMultiselect from 'vue-multiselect';
export default {
  name: "UserAdditionalInfoUpdateOrCreateModal",
  mixins: [ShowToastMessage, Loader],
  props: {
    modalName: {
      type: String,
      required: true,
      default: "UserAdditionalInfoCreateModal"
    },
    type: {
      type: Number,
      default: null, // null for update both email or phone number, 0 for update email only, 1 for update phone only.
    },
    userId: {
      type: [Number, String],
      required: true,
    },
    userAdditionalInfoId: {
      type: [Number, String],
      default: null, // null for create, else for update
    },
    userAdditionalInfoExistingDataValue: {
      type: String,
      default: '',
    },
    userAdditionalInfoExistingDataLabel: {
      type: String,
      default: '',
    },
  },
  components: {
    VueMultiselect,
  },
  data() {
    return {
      postUserAdditionalInfoData: {
        user_id: '',
        type: '',
        value: '',
        label: '',
      },
      types: [
        {
          value: 0,
          name: 'Email',
        },
        {
          value: 1,
          name: 'Phone Number',
        }
      ],
      selectedType: {
        value: '',
        name: 'None',
      },
      errors: {
        user_id: '',
        type: '',
        value: '',
        label: '',
      },
      getSettingsParams: {
        type: ['default'],
        key: ['default_state'],
      },
    }
  },
  computed: {
    ...mapGetters({
      userAdditionalInfo: 'appUserAdditionalInfos/userAdditionalInfo',
    }),

    modalTitle() {
      return this.userAdditionalInfoId === null ? "Create User's Additional Information" : "Update User's Additional Information";
    },

    typeWiseDivClass() {
      if (this.type !== null) {
        return 'col-12 col-md-6';
      }
      return 'col-12 col-md-4';
    },
    valueLabel() {
      if (this.postUserAdditionalInfoData.type === 0 || this.postUserAdditionalInfoData.type === '0') {
        return 'Email';
      }
      if (this.postUserAdditionalInfoData.type === 1 || this.postUserAdditionalInfoData.type === '2') {
        return 'Phone Number';
      }
      return 'Email/ Phone Number';
    },
  },
  watch: {
    selectedType(selectedType) {
      this.postUserAdditionalInfoData.type = selectedType.value;
    },
    userId(userId) {
      this.postUserAdditionalInfoData.user_id = userId;
    },
    type(type) {
      this.postUserAdditionalInfoData.type = type;
    },
    userAdditionalInfoExistingDataLabel(userAdditionalInfoExistingDataLabel) {
      this.postUserAdditionalInfoData.label = userAdditionalInfoExistingDataLabel;
    },
    userAdditionalInfoExistingDataValue(userAdditionalInfoExistingDataValue) {
      this.postUserAdditionalInfoData.value = userAdditionalInfoExistingDataValue;
    },
  },
  methods: {
    ...mapActions({
      postUserAdditionalInfo: 'appUserAdditionalInfos/postUserAdditionalInfo',
      putUserAdditionalInfo: 'appUserAdditionalInfos/putUserAdditionalInfo',
    }),

    async closeModal() {
      document.querySelector(`[data-target="#${this.modalName}"]`).click(); // modal close
    },

    async emitUserAdditionalInfoData(userAdditionalInfoObj) {
      this.$emit('userAdditionalInfoData', {
        UserAdditionalInfo: userAdditionalInfoObj,
      });
    },



    async updateSingleUserAdditionalInfo() {
      this.loader(true);
      let dataObj = {
        id: this.userAdditionalInfoId,
        data: {
          type: this.postUserAdditionalInfoData.type,
          value: this.postUserAdditionalInfoData.value,
          label: this.postUserAdditionalInfoData.label,
        }
      }
      await this.putUserAdditionalInfo(dataObj).then(async (response) => {
        if (response.status === 200 || response.status === 201) {

          let userAdditionalInfo = this.$store.getters['appUserAdditionalInfos/userAdditionalInfo'];

          let successToastObj = {message: 'Additional email updated successfully.', type: 'success'};

          if (this.postUserAdditionalInfoData.type === 1) {
            successToastObj = {message: 'Additional phone number updated successfully.', type: 'success'};
          }
          await this.closeModal();
          this.showToastMessage(successToastObj);
          await this.emitUserAdditionalInfoData(userAdditionalInfo);
        }
        this.errors.value = response.errors && response.errors.value ? response.errors.value[0] : '';
        this.errors.label = response.errors && response.errors.label ? response.errors.label[0] : '';

        this.showToastMessage(response);
      });
      this.loader(false);
    },

    async createSingleUserAdditionalInfo() {
      this.loader(true);
      await this.postUserAdditionalInfo(this.postUserAdditionalInfoData).then(async (response) => {
        if (response.status === 200 || response.status === 201) {

          let userAdditionalInfo = this.$store.getters['appUserAdditionalInfos/userAdditionalInfo'];

          let successToastObj = {message: 'Email added as Additional Info, successfully.', type: 'success'};

          if (this.postUserAdditionalInfoData.type === 1) {
            successToastObj = {message: 'Phone number added as Additional Info, successfully.', type: 'success'};
          }
          await this.closeModal();
          this.showToastMessage(successToastObj);
          await this.emitUserAdditionalInfoData(userAdditionalInfo);
        }

        this.errors.user_id = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';
        this.errors.type = response.errors && response.errors.type ? response.errors.type[0] : '';
        this.errors.value = response.errors && response.errors.value ? response.errors.value[0] : '';
        this.errors.label = response.errors && response.errors.label ? response.errors.label[0] : '';

        this.showToastMessage(response);
      });
      this.loader(false);
    },

  }
}
</script>